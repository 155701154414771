import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import MetaImage from '../assets/images/books/build-cover.jpg'
import { Chapter, buildBookChapters } from '../bookUtils'
import Block from '../components/Block'
import Blockquote from '../components/Blockquote'
import { BlogCta } from '../components/BlogCta'
import { BookToc } from '../components/BookToc'
import Layout from '../components/Layout'
import { MenuToggle } from '../components/MenuToggle'
import SidebarLayout from '../components/SidebarLayout'
import { TableOfContents } from '../components/TableOfContents'
import YoutubeVideo from '../components/YoutubeVideo'
import BuildBookTitle from '../components/book/BuildBookTitle'
import Box from '../components/primitives/Box'
import Button from '../components/system/Button'
import {
  useStoreBuildBookLocation,
  useTrackCurrentHeading,
} from '../navigationUtils'
import theme from '../theme'
import { Heading, parseGraphQLHeadings } from '../tocUtils'

const topPadding = 32

export default function BookTemplate({ data, pageContext }) {
  const { body } = data.mdx

  const { currentHeadingId } = useTrackCurrentHeading(pageContext.slug)
  useStoreBuildBookLocation(currentHeadingId)
  const { currentHeadingId: currentH2HeadingId } = useTrackCurrentHeading(
    pageContext.slug,
    'h2',
  )

  const chapterTitle = data.mdx.headings[0].value
  const url = 'https://www.swarmia.com' + data.mdx.path
  const title = chapterTitle + ' | Build | Swarmia'
  const description = ''

  const [headings] = useState(() =>
    parseGraphQLHeadings(
      data.mdx.headings
        .filter(h => h.depth === 2)
        .map(h => ({ ...h, depth: 0 })),
    ),
  )

  const content = (
    <>
      <div className="blog-post-content">
        <MDXProvider
          components={{
            // NOTE: If we start having many components here, implement lazy loading to keep things fast
            // See: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/#lazy-loading-components
            BlogCta,
            blockquote: props => <Blockquote {...props} url={url} />,
            YoutubeVideo: props => <YoutubeVideo borderRadius={0} {...props} />,
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </>
  )

  const footer = (
    <Box>
      <Box display="flex" justifyContent="space-between" paddingBottom={48}>
        {pageContext.previousPath ? (
          <Button variant="secondary" href={pageContext.previousPath}>
            ← Previous chapter
          </Button>
        ) : (
          <div />
        )}
        {pageContext.nextPath ? (
          <Button variant="secondary" href={pageContext.nextPath}>
            Next chapter →
          </Button>
        ) : (
          <div />
        )}
      </Box>
      <Box padding={24} font="small" color="black600" textAlign="center">
        Copyright © 2024 Swarmia. All rights reserved.
      </Box>
    </Box>
  )

  return (
    <Layout justifyCenter hideNavigation metaImage={MetaImage}>
      <Helmet title={title}>
        <meta name="description" content={description} />
        <meta name="author" content="Rebecca Murphey" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={chapterTitle} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@rmurphey" />
        <meta name="twitter:title" content={chapterTitle} />
        <meta name="twitter:description" content={description} />
      </Helmet>

      <Block size="large">
        <BlogPost isLong={true}>
          <SidebarLayout
            sidebar={
              <StickySidebar
                headings={headings}
                title={chapterTitle}
                titleId=""
                chapters={buildBookChapters(data.chapters)}
                currentHeadingId={currentH2HeadingId}
              />
            }
            main={content}
          />
          <SidebarLayout sidebar={<div />} main={footer} />
        </BlogPost>
      </Block>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      wordCount {
        words
      }
      headings {
        value
        depth
      }
    }
    chapters: allMdx(
      filter: { fileAbsolutePath: { regex: "/src/books/build/" } }
      sort: { order: ASC, fields: [slug] }
      limit: 1000
    ) {
      edges {
        node {
          id
          slug
          headings {
            value
            depth
          }
        }
      }
    }
  }
`

const imageAltStyles = css`
  line-height: 24px;
  color: ${theme.colors.black500};
  font-size: 16px;
`
const BlogPost = styled.div<{ isLong?: boolean }>`
  margin: 0 auto;

  // Hide icons generated by gatsby-remark-autolink-headers
  a.anchor.before {
    display: none;
  }

  .blog-post-content {
    table {
      margin: 2rem -1rem;

      td {
        padding: 0.5rem 1rem;
        font-size: 18px;
        line-height: 1.6;

        @media (max-width: ${theme.breakpoints['small']}) {
          font-size: 12px;
        })

        @media (max-width: ${theme.breakpoints['medium']}) {
          font-size: 16px;
        })
      }
    }

    padding-top: 2rem;
    margin-bottom: 2rem;

    h2,
    h3,
    h4,
    h5,
    h6 {
      width: 100%;
      scroll-margin-top: 100px;
    }


    .inline-list {
      padding-left: 2.5rem;

      li::before {
        content: '';
        width: 0.5rem;
        display: inline-block;
      }

      margin-bottom: 0;
    }

    cite {
      display: block;
      font-size: 0.75em;
      line-height: 1.8em;
      margin-top: -2rem;
      margin-bottom: 3rem;
    }

    .gatsby-highlight {
      margin: 2rem 0;
      font-size: 14px;

      pre {
        padding:1.5rem;
        border-radius:8px;
      }
    }

    & > p,
    & > div,
    & > a,
    & > .gatsby-resp-image-figure,
    & > .gatsby-resp-image-wrapper,
    & > .sidebar > .gatsby-resp-image-figure,
    & > .sidebar > .gatsby-resp-image-wrapper {
      display: block;
      margin-top: 32px;
    }

    .gatsby-resp-image-wrapper + .gatsby-resp-image-wrapper {
      margin-top: 0;
    }

    .gatsby-resp-image-figure {
      .gatsby-resp-image-wrapper {
        margin-bottom: 16px;
      }

      figcaption {
        ${imageAltStyles}
      }
    }

    .gatsby-resp-image-wrapper {
      &,
      img,
      .gatsby-resp-image-background-image {
        border-radius: 12px;
      }
    }

    .no-radius .gatsby-resp-image-wrapper {
      &,
      img,
      .gatsby-resp-image-background-image {
        border-radius: 0 !important;
      }
    }

    @media (min-width: 1349px) {
      --image-outset: ${props => (props.isLong ? '-40px' : '-180px')};
      --quote-outset: ${props => (props.isLong ? '-40px' : '-80px')};

      .gatsby-resp-image-wrapper {
        margin-left: var(--image-outset) !important;
        margin-right: var(--image-outset) !important;

        &,
        img,
        .gatsby-resp-image-background-image {
          border-radius: 32px;
        }
      }

      .max-width-100 .gatsby-resp-image-wrapper,
      .sidebar .gatsby-resp-image-wrapper {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      blockquote,
      cite {
        margin-left: var(--quote-outset);
        margin-right: var(--quote-outset);
      }
    }

    .sidebar {
      padding: 48px;
      margin: 48px -32px;
      border-radius: 24px;

      @media (min-width: 1349px) {
        border-radius: 32px;
      }

      @media (max-width: ${theme.breakpoints['large']}) {
        padding: 24px;
        margin: 32px -10px;
      }

      &, h2, h3, h4 {
        color: #000;
      }

      figcaption {
        color: #3b3b3b;
      }

      .gatsby-resp-image-image {
        // Prevent white background
        box-shadow: none !important;
      }
    }

    .sidebar > h2:first-child,
    .sidebar > h3:first-child {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .sidebar-peach {
      background-color: #FEE4D7;
    }
    .sidebar-pink {
      background-color: #FDE9F2;
    }
    .sidebar-blue {
      background-color: #EDF8FE;
    }
    .sidebar-green {
      background-color: #DBEFE7;
    }
    .sidebar-yellow {
      background-color: #FFCF01;
    }

    .title-list {
      margin-bottom: 64px;

      h3 {
        font-size: 22px;
        padding: 16px;
        background-color: #EDF8FE;
        color: #000;
        border-radius: 16px;
        text-align: center;
        text-transform: uppercase;
        margin: 3rem -16px 2rem;
        width: auto;
      }

      li {
        margin: 0.5em 0;
      }
    }
`

function StickySidebar({
  headings,
  title,
  titleId,
  chapters,
  currentHeadingId,
}: {
  headings: Heading[] | null
  title: string
  titleId: string
  chapters: Chapter[]
  currentHeadingId: string
}) {
  const { ref, inView } = useInView({
    rootMargin: `0px 0px 0px 0px`,
    fallbackInView: true,
    initialInView: true,
  })
  const [showFullToc, setShowFullToc] = useState(false)

  const toggleMenu = () => {
    setShowFullToc(!showFullToc)
  }

  useEffect(() => {
    document.body.style.overflow = showFullToc ? 'hidden' : 'unset'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [showFullToc])

  return (
    <div style={{ height: '100%' }}>
      <div ref={ref} />
      <SidebarContent isDimmed={!showFullToc && !inView} isFixed={showFullToc}>
        <Button
          onClick={toggleMenu}
          variant="ghost"
          size="large"
          marginTop={8}
          paddingX={12}
        >
          <MenuToggle active={showFullToc} />
          <Box fontSize={24} paddingLeft={10} paddingTop={4}>
            Build
          </Box>
        </Button>
        {showFullToc ? (
          <Box paddingTop={16} paddingLeft={12} paddingBottom={48}>
            <Button
              href="/"
              variant="ghost"
              size="medium"
              paddingX={12}
              css="margin-left: -12px;"
              marginBottom={32}
            >
              ← &nbsp; Swarmia.com
            </Button>

            <Box paddingBottom={48}>
              <BuildBookTitle />
            </Box>
            <BookToc
              chapters={chapters}
              onLinkClick={() => setShowFullToc(false)}
            />
          </Box>
        ) : (
          <Box paddingTop={32} className="toc-wrapper">
            {headings ? (
              <TableOfContents
                title={title}
                titleId={titleId}
                headings={headings}
                currentHeadingId={currentHeadingId}
              />
            ) : null}
          </Box>
        )}
      </SidebarContent>
    </div>
  )
}

const SidebarContent = styled.div<{ isDimmed: boolean; isFixed: boolean }>`
  position: ${props => (props.isFixed ? 'fixed' : 'sticky')};
  top: 0;
  z-index: 10;
  padding-top: ${topPadding}px;

  // This is when the sidebar layout collapses
  @media screen and (max-width: ${theme.breakpoints['large']}) {
    .toc-wrapper {
      display: none;
    }
  }

  ${props =>
    props.isDimmed
      ? `
  opacity: 0.25;
  transition: opacity 0.15s ease;
  &:hover {
    opacity: 1;
  }`
      : ``}

  ${props =>
    props.isFixed
      ? `
      background-color: #fff;
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
      `
      : `
      max-height: calc(100vh - 40px);
      `}
`
